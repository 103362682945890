import 'magnific-popup';
import './bit';

jQuery( document ).ready(function($) {
    $('#dates').bit({ 
        'artist'    : 'Jordan Fletcher',
        show_y      : false,
        template    : $(`
                <div class="event">
                    <div class="date">
                        <span class="dow"></span>
                        <span class="month"></span>
                        <span class="day"></span>
                        <span class="year"></span>
                    </div>
                    <div class="location">
                        <span class="venue"></span>
                    </div>
                    <div class="location">
                        
                        <span class="city">,</span>
                        <span class="region">,</span>
                        <span class="country"></span>
                    </div>
                    <div class="tickets"></div>
                </div>
            `),
    });
});